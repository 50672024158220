<template>
  <div class="find-id">
    <div class="wrapper">
      <div class="title font-md">
        <span>아이디 찾기</span>
      </div>
      <div class="desc font-sm">기존 가입 회원정보에 휴대전화 번호가 등록 되어있지 않았을 경우, 아이디 찾기 서비스에 제한이 있을 수 있습니다.</div>
      <div class="form">
        <input :id="`${component.name}Name`" type="text" class="form-control border-focus-point" v-model="state.form.name" autocomplete="off" placeholder="이름" @keyup.enter="submit()"/>
        <Phone :id="`${component.name}Mobile`" placeholder="휴대전화" :value.sync="state.form.mobile" :enter="submit"/>
      </div>
    </div>
    <div class="action">
      <button class="btn btn-point btn-block font-sm" @click="submit()">아이디 찾기</button>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";
import Phone from "@/components/Phone";

function Component(initialize) {
  this.name = "modalFindId";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Phone},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: "sm"});
    });

    const state = reactive({
      form: {
        name: "",
        mobile: "",
        email: "",
      }
    });

    const submit = () => {
      const args = {
        memberName: state.form.name,
        mobile: state.form.mobile,
      };

      if (!args.memberName) {
        store.commit("setSwingMessage", "이름을 입력해주세요.");
        document.getElementById(`${component.name}Name`).focus();
        return;
      } else if (!args.mobile) {
        store.commit("setSwingMessage", "휴대전화를 입력해주세요.");
        document.getElementById(`${component.name}Mobile`).focus();
        return;
      }

      store.commit("openModal", {
        name: "FindResult",
        params: {
          args: args
        }
      });
    };

    return {component, state, submit};
  },
});
</script>

<style lang="scss" scoped>
.find-id {
  > .wrapper {
    background: #fff;
    padding: $px25;

    .title {
      margin-bottom: $px9;
    }

    .desc {
      margin-bottom: $px25;
      color: #666;
    }

    .form {
      input {
        display: block;
        margin: $px13 0;
        height: $formHeightLg;
        font-size: $px14;
        border-color: #ddd;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .action {
    .btn {
      padding: $px15;
      height: $px56;
      border-radius: 0;
    }
  }
}
</style>